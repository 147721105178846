// Step 1: Import React
import * as React from 'react'

// Step 2: Define your component
const IndexPage = () => {
  return (
    <main>
      <h1>Welcome to my first Gatsby site!</h1>
      <p>Now using github actions.</p>
    </main>
  )
}

// You will learn about this in the next task
export const Head = () => <title>Home Page</title>

// Step 3: Export component
export default IndexPage
